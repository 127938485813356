import { notification } from 'antd'
import { getLeaveReport } from 'services/axios/leaveReport'

// Action Types
export const actions = {
  FETCH_EMPLOYEE_LEAVE_LIST: 'FETCH_EMPLOYEE_LEAVE_LIST',
  FETCH_EMPLOYEE_LEAVE_SUCCESS: 'FETCH_EMPLOYEE_LEAVE_SUCCESS',
  FETCH_EMPLOYEE_LEAVE_FAILURE: 'FETCH_EMPLOYEE_LEAVE_FAILURE',
  LEAVE_HANDLE_PAGINATION: 'LEAVE_HANDLE_PAGINATION',
  HANDEL_YEAR: 'HANDEL_YEAR',
}

// Fetch Employee Leave List
export const getEmployeeLeaveList = ({ skip, limit, year }) => {
  return async (dispatch, getState) => {
    const { loading } = getState().leaveReport
    if (loading) {
      return // Don't call the API again
    }
    dispatch({ type: actions.FETCH_EMPLOYEE_LEAVE_LIST })

    try {
      const response = await getLeaveReport({ skip, limit, year })

      // Check if the data exists at the correct location in the response
      if (response?.data?.data?.length > 0) {
        dispatch({
          type: actions.FETCH_EMPLOYEE_LEAVE_SUCCESS,
          payload: {
            employeeLeaveList: response.data.data,
            totalCount: response.data.totalCount,
          },
        })
      } else {
        dispatch({
          type: actions.FETCH_EMPLOYEE_LEAVE_FAILURE,
          payload: 'No data returned from API',
        })
        notification.error({ message: 'No data returned from API' })
      }
    } catch (error) {
      console.error('API call failed with error: ', error.message)
      dispatch({
        type: actions.FETCH_EMPLOYEE_LEAVE_FAILURE,
        payload: error.message,
      })
      notification.error({ message: error.message })
    }
  }
}

// Handle Year Change Action
export const handleYear = (year) => {
  return {
    type: actions.HANDEL_YEAR,
    payload: year,
  }
}

// Handle Pagination Action
export const handlePagination = (pagination) => {
  return {
    type: actions.LEAVE_HANDLE_PAGINATION,
    payload: pagination,
  }
}
